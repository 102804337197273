import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    clientId: "833c0ce9-ca05-4ab6-88c0-ffe82fe9d158",
    authority: "https://login.microsoftonline.com/33a0f4d4-f8f3-481c-bdeb-9868b68e1596",
    redirectUri: "https://safetycoaching.hirschbach.com/",

  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};

export const powerBiConfig = {
  reportTokenEndpoint: "https://api.powerbi.com/v1.0/myorg/groups/502ebe91-0dd1-4a1b-99f1-7e6d41de1ced/reports/bc15483c-ffb5-43d1-b0f3-75f0e3498a68/GenerateToken",
};

export const graphScopes = [
  "https://graph.microsoft.com/User.Read",
  "https://graph.microsoft.com/User.ReadBasic.All",
];

export const powerBiScopes = ["https://analysis.windows.net/powerbi/api/.default"];
