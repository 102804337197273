import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import './SpeedReport.css';

const SpeedReport = ({ powerBiToken, DriverCode }) => {
  // Define a filter for "Driver Code = Hamda"
  const reportFilter = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "SpeedingEvents", 
      column: "Driver Code"
    },
    operator: "In",
    values: [DriverCode] 
  };

  return (
    <PowerBIEmbed 
      embedConfig={{
        type: 'report',
        id: 'bc15483c-ffb5-43d1-b0f3-75f0e3498a68',
        embedUrl: 'https://app.powerbi.com/reportEmbed?reportId=bc15483c-ffb5-43d1-b0f3-75f0e3498a68&groupId=502ebe91-0dd1-4a1b-99f1-7e6d41de1ced&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVVTLUNFTlRSQUwtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwiZGlzYWJsZUFuZ3VsYXJKU0Jvb3RzdHJhcFJkbEVtYmVkIjp0cnVlfX0%3d',
        accessToken: powerBiToken,
        tokenType: models.TokenType.Aad,
        settings: {
          panes: {
            filters: {
              expanded: false,
              visible: false,
            },
            pageNavigation: {
              visible: false
            }
          },
          background: models.BackgroundType.Transparent,
        },
        filters: [reportFilter],
        pageName: "ReportSectiona3f587fc98703055ef33",
      }}
      eventHandlers={new Map([
        ['loaded', function () { console.log('Report loaded'); }],
        ['rendered', function () { console.log('Report rendered'); }],
        ['error', function (event) { console.log(event.detail); }],
        ['visualClicked', () => console.log('visual clicked')],
        ['pageChanged', (event) => console.log(event)],
      ])}
      cssClassName="Embeded-container"
      getEmbeddedComponent={(embeddedReport) => {
        window.report = embeddedReport;
      }}
    />
  );
};

export default SpeedReport;
