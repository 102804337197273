import React from "react";
import styled from "@emotion/styled";
import { Spinner } from "@chakra-ui/react";
import { motion, AnimatePresence } from "framer-motion";

const LoadingMessage = styled.p`
  color: #ffffff;
  font-size: 24px; /* Increased from 18px to 24px */
  font-weight: 500;
  margin-top: 20px;
  text-align: center;
`;

const CenteredContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 70px; /* Adjust the margin-top value to 70px */
  height: calc(100vh - 150px); /* Set the height to 100vh minus 70px */
  background-color: rgba(255, 255, 255, 0.025);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-radius: 12px;
`;

const fadeVariant = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 1.5 } }, // Increased duration from 0.8 to 1.5
  exit: { opacity: 0, transition: { duration: 1.5 } }, // Increased duration from 0.8 to 1.5
};

const Loading = () => {
  return (
    <CenteredContainer
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Spinner
        size="xl"
        color="white"
        thickness="4px"
        emptyColor="gray.200"
        speed="3s"
      />

      {/* Use AnimatePresence to handle the component's unmounting transition */}
      <AnimatePresence exitBeforeEnter>
        <motion.div
          key="loading"
          variants={fadeVariant}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <LoadingMessage>Connecting...</LoadingMessage>
        </motion.div>
      </AnimatePresence>
    </CenteredContainer>
  );
};

export default Loading;
