import React from "react";
import {
  Button,
  Box,
  Flex,
  Center,
  Icon,
  VStack,
  Image,
} from "@chakra-ui/react";
import { FaMicrosoft } from "react-icons/fa";
import { motion } from "framer-motion"; // Import motion from Framer Motion

interface LoginProps {
  handleLogin: () => void;
}

export default function Login({ handleLogin }: LoginProps) {
  return (
    <motion.div // Wrap your entire component with motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <Box
        textAlign="center"
        py={10}
        px={6}
        bg="whiteAlpha.600"
        borderWidth="1px"
        borderRadius="md"
        shadow="xl"
        maxW="500px"
        mx="auto"
        mt={10}
      >
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          mb={5}
        >
          <Image src="/hirschbach.png" alt="Hirschbach Logo" />
        </Flex>
        <Center>
          <VStack>
            <Button
              leftIcon={<Icon as={FaMicrosoft} />}
              colorScheme="blackAlpha"
              onClick={handleLogin}
              variant="outline"
              borderColor="blackAlpha.200"
              shadow="md"
            >
              Authenticate
            </Button>
          </VStack>
        </Center>
      </Box>
    </motion.div>
  );
}
