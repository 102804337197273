import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";

export default function SuccessMessage() {
  return (
    <Box
      textAlign="center"
      py={10}
      px={6}
      bg="white"
      borderWidth="1px"
      borderRadius="md"
      shadow="xl"
      maxW="500px"
      mx="auto"
      mt={10}
    >
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        mb={5}
      >
        <Box
          bg={"green.500"}
          rounded={"full"}
          w={"55px"}
          h={"55px"}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CheckIcon boxSize={"20px"} color={"white"} />
        </Box>
        <Heading as="h2" size="lg" mt={6} mb={2}>
          Success
        </Heading>
      </Flex>
      <Text color={"gray.600"} fontSize="md">
        Your Safety Coaching response has been successfully submitted. If you
        chose the 'Attempted Contact' option, the action item will still be
        open. If not, the action item will be closed. Thank you.
      </Text>
    </Box>
  );
}
