import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom

const TopBar = () => {
  const navigate = useNavigate(); // Get the navigate function from React Router

  const handleUnitClick = () => {
    navigate("/"); // Navigate to the home page ("/")
  };

  return (
    <Flex
      align="center"
      p={2}
      height="70px"
      css={{
        backdropFilter: "blur(10px)",
      }}
    >
      <Box
        onClick={handleUnitClick} // Use a single handler for the entire unit
        style={{ cursor: "pointer" }}
        display="flex"
        alignItems="center"
      >
        <Image src="/hirschbach.png" alt="Logo" maxH={16} />
        <Text fontSize="xl" fontWeight="medium" color="gray" marginLeft="10px">
          {" | "}
          <span style={{ color: "Orange" }}>Safety</span>{" "}
          <span style={{ color: "gray" }}>Coaching</span>
        </Text>
      </Box>
    </Flex>
  );
};

export default TopBar;
