import axios from "axios";

export interface Payload {
  [key: string]: any;
}

export const API = async (payload: Payload) => {
  const url =
    "https://prod-73.westus.logic.azure.com:443/workflows/641c4ec5baa841f48a5236c9daaaf6d5/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=00SVI2ZxLHzWpzE-TkcbwYqzfRPhAAhVU8PM6GNdg0s";

  try {
    const response = await axios.post(url, payload, {
      headers: {},
    });

    return response.data;
  } catch (error) {
    console.error("API request failed:", error);
    throw error;
  }
};
