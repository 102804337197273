import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";

// Component imports
import TopBar from "./components/Global/TopBar";
import MainPage from "./layouts/MainPage";
import Session from "./layouts/Session";
import SuccessMessage from "./components/Session/Success";
import Dashboard from "./layouts/Dashboard";
import BusinessIntelligenceReport from "./layouts/BusinessIntelligenceReport";
import Archive from "./layouts/Archive";

// MSAL & authentication imports
import Login from "./layouts/LoginPage";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { graphScopes, powerBiScopes } from "./authConfig";
import { callMsGraph } from "./graph";

// Main app component
const App = () => {
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [powerBiReportToken, setPowerBiReportToken] = useState<string | null>(
    null
  );

  //background
  const appStyles = {
    backgroundImage: "url('/background.jpg')",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    minHeight: "100vh",
  };

  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const handleLogin = () => {
    instance
      .loginRedirect({
        scopes: graphScopes,
      })
      .catch((error) => {
        console.error("Login error:", error);
      });
  };

  useEffect(() => {
    const requestTokenForScope = (scopes: string[]) => {
      return instance.acquireTokenSilent({
        scopes: scopes,
        account: accounts[0],
      });
    };

    const RequestProfileData = () => {
      requestTokenForScope(graphScopes)
        .then((graphTokenResponse) => {
          // Call Microsoft Graph with the token
          return callMsGraph(graphTokenResponse.accessToken).then(
            (graphResponse) => {
              setUserName(graphResponse.displayName);
              setUserEmail(
                graphResponse.mail || graphResponse.userPrincipalName
              );
              return graphTokenResponse.accessToken;
            }
          );
        })
        .then(() => {
          return requestTokenForScope(powerBiScopes);
        })
        .then((powerBiTokenResponse) => {
          // Check if the Power BI scopes are present in the acquired token.
          // The scopes might be present in the token's 'scopes' property, but you'll need to check based on your specific implementation.
          if (
            powerBiTokenResponse.scopes &&
            !powerBiScopes.every((scope) =>
              powerBiTokenResponse.scopes.includes(scope)
            )
          ) {
            console.warn(
              "User hasn't given permissions for Power BI. Re-initiating login..."
            );
            handleLogin();
          } else {
            setPowerBiReportToken(powerBiTokenResponse.accessToken);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    };

    if (isAuthenticated && accounts.length > 0) {
      RequestProfileData();
    }
  }, [isAuthenticated, accounts, instance, powerBiScopes, graphScopes]);

  return (
    <div style={appStyles}>
      <TopBar />
      {isAuthenticated ? (
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route
            path="/session/:ID"
            element={<Session powerBiToken={powerBiReportToken} />}
          />
          <Route path="/success" element={<SuccessMessage />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/archive/:ID" element={<Archive />} />
          <Route
            path="/report"
            element={
              <BusinessIntelligenceReport powerBiToken={powerBiReportToken} />
            }
          />
        </Routes>
      ) : (
        <Login handleLogin={handleLogin} />
      )}
    </div>
  );
};

export default App;
