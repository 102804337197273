import React from "react";
import { Box } from "@chakra-ui/react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import DOMPurify from "dompurify";
import {
  SafetyCoachingDetail,
  SafetyCoachingInteraction,
  TimelineProps,
} from "../../types/Types";

// Function to format a date string or return "N/A" if it's null
function formatDate(dateStr: string | null): string {
  if (!dateStr) return "N/A";
  const date = new Date(dateStr);
  return `${(date.getMonth() + 1).toString().padStart(2, "0")}-${date
    .getDate()
    .toString()
    .padStart(2, "0")}-${date.getFullYear()}`;
}

// Function to sanitize HTML using DOMPurify
const sanitizeHTML = (html: string | null): { __html: string } => {
  if (!html) return { __html: "" };
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");

  // Remove font type and color attributes
  doc.querySelectorAll("*[style]").forEach((el) => {
    el.removeAttribute("style");
  });

  // Get the sanitized HTML using DOMPurify
  const sanitizedHTML = DOMPurify.sanitize(doc.documentElement.innerHTML);
  return { __html: sanitizedHTML };
};

// Interaction Information Component inside the timeline
const InteractionDetails: React.FC<{
  interaction: SafetyCoachingInteraction;
  detail: SafetyCoachingDetail;
}> = ({ interaction }) => (
  <div
    style={{
      padding: "10px",
      borderRadius: "8px",
      background: "#f2f2f2",
      color: "#333",
    }}
  >
    <span style={{ color: "#666", fontSize: "1em", fontWeight: "bold" }}>
      {interaction.Event || "N/A"}
    </span>
    <br />
    <span style={{ color: "#666", fontWeight: "normal" }}>
      {interaction.Caller || "N/A"}
    </span>
    <br />
    {/* ... similar for other labels and values */}
    <div
      style={{
        marginTop: "10px",
        padding: "8px",
        borderRadius: "8px",
        background: "#e6e6e6",
      }}
    >
      {interaction.Comment ? (
        <div
          style={{ fontSize: "0.9em", lineHeight: "1.5em" }}
          dangerouslySetInnerHTML={sanitizeHTML(interaction.Comment)}
        />
      ) : (
        <div>No comment available</div>
      )}
    </div>
  </div>
);

// Timeline Component
const Timeline: React.FC<TimelineProps> = ({ details }) => (
  <Box width="100%" height="650px" position="relative" overflowY="auto">
    <VerticalTimeline>
      {details.map((detail, index) => (
        <React.Fragment key={index}>
          {detail.Interaction.sort((a, b) => {
            // Sort in descending order based on date
            const dateA = new Date(a.Date || "");
            const dateB = new Date(b.Date || "");
            return dateB.getTime() - dateA.getTime();
          }).map((interaction, i) => (
            <VerticalTimelineElement
              key={i}
              date={formatDate(interaction.Date)}
              iconStyle={{
                background: "#666", // Use a lighter gray color
                color: "#fff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "26px",
              }}
              icon={interaction.Rating || "N/A"}
              contentStyle={{
                background: "rgba( 255, 255, 255, 0.25 )",
                boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
                backdropFilter: "blur( 4px )",
                borderRadius: "10px",
                color: "#FFFFFF",
                border: "1px solid rgba( 255, 255, 255, 0.18 )",
              }}
              contentArrowStyle={{
                borderRight: "7px solid  rgba( 255, 255, 255, 0.18 )",
              }}
            >
              <InteractionDetails interaction={interaction} detail={detail} />
            </VerticalTimelineElement>
          ))}
        </React.Fragment>
      ))}
    </VerticalTimeline>
  </Box>
);

export default Timeline;
