import React from "react";
import {
  Heading,
  Text,
  Flex,
  Center,
  Badge,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import CountUp from "react-countup";

const BackgroundStyle = {
  backdropFilter: "blur(16px) saturate(180%)",
  WebkitBackdropFilter: "blur(16px) saturate(180%)",
  backgroundColor: "rgba(255, 255, 255, 0.2)",
  borderRadius: "10px",
  color: "gray.600",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "5px",
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
  width: "250px",
  height: "100px",
};

interface DashboardCardsProps {
  totalRecords: number;
  currentItems: number;
  averageTime: number;
  selectedStatus: string;
}

const DashboardCards: React.FC<DashboardCardsProps> = ({
  totalRecords,
  currentItems,
  averageTime,
  selectedStatus,
}) => {
  return (
    <Center>
      <Wrap spacing={6} justify="center" width="100%">
        {/* Card 1: Total Number of Records */}
        <WrapItem>
          <Flex sx={BackgroundStyle}>
            <Text fontSize="2xl" fontWeight="bold">
              <CountUp end={totalRecords} />
            </Text>
            <Heading size="sm" mt={2}>
              Total
            </Heading>
          </Flex>
        </WrapItem>

        {/* Card 2: Current Table Items */}
        <WrapItem>
          <Flex sx={BackgroundStyle}>
            <Text fontSize="2xl" fontWeight="bold">
              <CountUp end={currentItems} />
            </Text>
            <Heading size="sm" mt={2}>
              Current
            </Heading>
          </Flex>
        </WrapItem>

        {/* Card 3: Time duration (only shown for "Incomplete") */}
        {selectedStatus === "Incomplete" && (
          <WrapItem>
            <Flex sx={BackgroundStyle}>
              <Text fontSize="2xl" fontWeight="bold">
                {averageTime} days
              </Text>
              <Heading size="sm" mt={2}>
                Average Opening Days
              </Heading>
            </Flex>
          </WrapItem>
        )}
      </Wrap>
    </Center>
  );
};

export default DashboardCards;
