import React from "react";
import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

export default function MainPage() {
  const bgColor = useColorModeValue(
    "rgba(255, 255, 255, 0.1)",
    "rgba(0, 0, 0, 0.1)"
  );
  const textColor = useColorModeValue("blackAlpha.800", "whiteAlpha.800");

  const MotionBox = motion(Box);
  const navigate = useNavigate();

  const handleBoxClick = (path: string) => {
    navigate(path);
  };

  return (
    <Flex
      height="50vh"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <MotionBox
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 3 }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="row"
        spacing={6}
        w="80%"
        gap={6}
      >
        <Box
          onClick={() => handleBoxClick("/dashboard")}
          w="30%"
          h="250px"
          bg={bgColor}
          boxShadow="xl"
          borderRadius="md"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          cursor="pointer"
          _hover={{
            transform: "scale(1.05)",
            transition: "transform 0.5s",
          }}
          mr={6}
        >
          <Text
            fontSize="2xl"
            fontWeight="bold"
            color={textColor}
            textAlign="center" // Center align the title
          >
            Dashboard
          </Text>
          <Text
            fontSize="md"
            color={textColor}
            mt={2}
            textAlign="center" // Center align the description
          >
            View current and historical safety coaching records
          </Text>
        </Box>

        <Box
          onClick={() => handleBoxClick("/report")}
          w="30%"
          h="250px"
          bg={bgColor}
          boxShadow="xl"
          borderRadius="md"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          cursor="pointer"
          _hover={{
            transform: "scale(1.05)",
            transition: "transform 0.5s",
          }}
        >
          <Text
            fontSize="2xl"
            fontWeight="bold"
            color={textColor}
            textAlign="center" // Center align the title
          >
            Analytics
          </Text>
          <Text
            fontSize="md"
            color={textColor}
            mt={2}
            textAlign="center" // Center align the description
          >
            Dive deep into the KPIs and metrics
          </Text>
        </Box>
      </MotionBox>
    </Flex>
  );
}
