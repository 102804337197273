import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

export default function ErrorMessage() {
  return (
    <Box
      textAlign="center"
      py={10}
      px={6}
      bg="white"
      borderWidth="1px"
      borderRadius="md"
      shadow="xl"
      maxW="500px"
      mx="auto"
      mt={10}
    >
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        mb={5}
      >
        <Box
          bg={"red.500"}
          rounded={"full"}
          w={"55px"}
          h={"55px"}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CloseIcon boxSize={"20px"} color={"white"} />
        </Box>
        <Heading as="h2" size="lg" mt={6} mb={2}>
          Encounter an error
        </Heading>
      </Flex>
      <Text color={"gray.600"} fontSize="md">
        Your Safety Coaching session cannot be found or the API is not
        responding to this Web Application. Please try again later or contact
        the Process Improvement Team.
      </Text>
    </Box>
  );
}
