import React, { useState, useEffect } from "react";
import Loading from "../components/Global/LoadingScreen";
import { API, Payload } from "../api/api";
import { motion } from "framer-motion";

import {
  VStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Flex,
  Input,
  Select,
  Button,
  IconButton,
  Td,
  Box,
  InputGroup,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Spacer,
  FormControl,
  FormLabel,
  Switch,
} from "@chakra-ui/react";
import { RepeatIcon } from "@chakra-ui/icons";
import { Eye28Regular } from "@fluentui/react-icons";
import DashboardCards from "../components/DashBoard/DashboardCards";
import { SafetyCoaching } from "../types/Types";

//Interface for the data

const BackgroundColor = {
  backdropFilter: "blur(10px) saturate(100%)",
  WebkitBackdropFilter: "blur(10px) saturate(100%)",
  backgroundColor: "rgba(30, 30, 30, 0.7)",
  borderRadius: "10px",
  color: "rgba(255, 255, 255, 0.85)",
  borderColor: "rgba(255, 255, 255, 0.5)",
  borderStyle: "solid",
};

export default function Dashboard() {
  // State initialization
  const [loading, setLoading] = useState(true);
  const [sortColumn, setSortColumn] = useState<keyof SafetyCoaching>("Created");
  const [sortDirection, setSortDirection] = useState("asc");
  const [showLatest, setShowLatest] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("Incomplete");
  const [filtering, setFiltering] = useState(true);
  const [data, setData] = useState<SafetyCoaching[]>([]);
  const [filteredData, setFilteredData] = useState<SafetyCoaching[]>([]);
  const [selectedDivision, setSelectedDivision] = useState<string | null>(
    "All Division"
  );
  const [selectedDriverLeader, setSelectedDriverLeader] = useState<
    string | null
  >("All Driver Leader");
  const [selectedRequested, setSelectedRequested] = useState<string | null>(
    "All Requested"
  );
  const [numberOfDays, setNumberOfDays] = useState<number>(7);
  const [averageTime, setAverageTime] = useState<number>(0);

  // Fetch Data
  const fetchData = () => {
    setLoading(true);
    setFiltering(true);

    const payload: Payload = {
      Process: "All",
    };

    API(payload)
      .then((responseData) => {
        setData(responseData);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("API call error:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Filter Data
  useEffect(() => {
    const filterData = () => {
      setFiltering(true);
      let tempData = [...data];

      // Filter by selectedStatus
      tempData = tempData.filter((item) => item.Status === selectedStatus);

      // Filter by selectedDivision
      if (selectedDivision !== "All Division") {
        tempData = tempData.filter(
          (item) => item.Division === selectedDivision
        );
      }

      // Filter by selectedDriverLeader
      if (
        selectedDriverLeader &&
        selectedDriverLeader !== "All Driver Leader"
      ) {
        tempData = tempData.filter(
          (item) => item.DriverLeaderName === selectedDriverLeader
        );
      }

      // Filter by selectedRequested
      if (selectedRequested && selectedRequested !== "All Requested") {
        tempData = tempData.filter(
          (item) => item.CreatedBy === selectedRequested
        );
      }

      // Filter "Completed" data by the selected number of days
      if (selectedStatus === "Completed" && showLatest) {
        const currentTime = new Date().getTime();
        let cutoffDate = new Date();
        const adjustedNumberOfDays = numberOfDays + 1;
        cutoffDate.setDate(cutoffDate.getDate() - adjustedNumberOfDays);
        tempData = tempData.filter((item) => {
          const createdDate = Date.parse(item.Created || "");
          return (
            createdDate >= cutoffDate.getTime() && createdDate <= currentTime
          );
        });
      }

      // Filter by searchInput
      if (searchInput) {
        const searchLowerCase = searchInput.toLowerCase();
        tempData = tempData.filter(
          (item) =>
            (item.DriverCode &&
              item.DriverCode.toLowerCase().includes(searchLowerCase)) ||
            (item.DriverName &&
              item.DriverName.toLowerCase().includes(searchLowerCase))
        );
      }
      // Decide on record capping based on conditions
      if (selectedStatus === "Incomplete") {
      } else if (selectedStatus === "Completed" && !showLatest) {
        tempData = tempData.slice(0, 100);
      }

      // Calculate the average time based on the filteredData
      const newAverageTime = calculateAverageIncompleteTime(tempData);
      setFilteredData(tempData);
      setFiltering(false);
      setAverageTime(newAverageTime); // Set the updated average time
    };

    filterData();
  }, [
    data,
    searchInput,
    selectedStatus,
    selectedDivision,
    selectedDriverLeader,
    selectedRequested,
    numberOfDays,
    showLatest,
  ]);

  // Sort Data
  const sortData = (column: keyof SafetyCoaching): SafetyCoaching[] => {
    const sortedData = [...filteredData];

    // Toggle the sorting direction
    const newSortDirection = sortDirection === "asc" ? "desc" : "asc";

    sortedData.sort((a, b) => {
      if (newSortDirection === "asc") {
        return a[column]! > b[column]! ? 1 : -1;
      } else {
        return a[column]! < b[column]! ? 1 : -1;
      }
    });
    setSortDirection(newSortDirection);
    return sortedData;
  };

  // Modify the handleRowClick function
  const handleRowClick = (id: number, status: string) => {
    let url;

    if (status === "Completed") {
      // If the status is "Completed," create a link like "/complete/ID"
      url = `/archive/${id}`;
    } else {
      // For other statuses, use the existing link
      url = `/session/${id}`;
    }

    window.open(url, "_blank");
  };

  // Calculate the average time for incomplete records
  function calculateAverageIncompleteTime(data: SafetyCoaching[]): number {
    const incompleteRecords = data.filter(
      (item) => item.Status === "Incomplete"
    );

    if (incompleteRecords.length > 0) {
      const totalIncompleteTime = incompleteRecords.reduce((acc, record) => {
        const createdDate = new Date(record.Created || "");
        const currentTime = new Date();
        const timeDifferenceInMilliseconds =
          currentTime.getTime() - createdDate.getTime();

        const timeDifferenceInDays =
          timeDifferenceInMilliseconds / (1000 * 60 * 60 * 24);

        return acc + timeDifferenceInDays;
      }, 0);

      const averageTimeInDays = totalIncompleteTime / incompleteRecords.length;
      return Math.floor(averageTimeInDays);
    }

    return 0;
  }

  // Unique values for Driver Leaders based on the selected "Status," "Division," and "Requested" filters
  const uniqueDriverLeaders = [
    "All Driver Leader",
    ...Array.from(
      new Set(
        data
          .filter((item) => {
            return (
              (selectedStatus === "All Status" ||
                item.Status === selectedStatus) &&
              (selectedDivision === "All Division" ||
                item.Division === selectedDivision) &&
              (selectedRequested === "All Requested" ||
                item.CreatedBy === selectedRequested)
            );
          })
          .map((item) => item.DriverLeaderName || "-")
      )
    ).sort(),
  ];

  // Unique values for Requested based on the selected "Status," "Division," and "Driver Leader" filters
  const uniqueRequested = [
    "All Requested",
    ...Array.from(
      new Set(
        data
          .filter((item) => {
            return (
              (selectedStatus === "All Status" ||
                item.Status === selectedStatus) &&
              (selectedDivision === "All Division" ||
                item.Division === selectedDivision) &&
              (selectedDriverLeader === "All Driver Leader" ||
                item.DriverLeaderName === selectedDriverLeader)
            );
          })
          .map((item) => item.CreatedBy || "-")
      )
    ).sort(),
  ];

  // Render the page
  return (
    <>
      {loading || filtering ? (
        <Loading />
      ) : (
        <VStack
          maxW="98%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          m={4}
          spacing={4}
        >
          <Flex
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            paddingX="20px"
            height="70px"
            width="100%"
            borderRadius="10px"
            bg="whiteAlpha.300"
            shadow="sm"
            position="sticky"
            top="1"
            zIndex="1"
          >
            {/* Search Input */}
            <InputGroup width="15%">
              <Input
                variant="flushed"
                placeholder="Name or Code"
                py="2"
                px="4"
                color="gray.700"
                focusBorderColor="crimson"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
            </InputGroup>

            {/* Filters */}
            <Flex
              alignItems="center"
              width="60%"
              justifyContent="space-between"
            >
              {/* Status Select */}
              <Select
                variant="flushed"
                py="2"
                px="4"
                color="gray.700"
                width="25%"
                focusBorderColor="crimson"
                value={selectedStatus}
                onChange={(e) => {
                  setSelectedStatus(e.target.value);
                  setShowLatest(false);
                }}
              >
                <option value="Incomplete">Incomplete</option>
                <option value="Completed">Complete</option>
              </Select>

              {/* Division Select */}
              <Select
                variant="flushed"
                py="2"
                px="4"
                color="gray.700"
                width="25%"
                focusBorderColor="crimson"
                value={selectedDivision || ""}
                onChange={(e) => {
                  setSelectedDivision(e.target.value);
                  setSelectedDriverLeader("All Driver Leader");
                }}
              >
                <option value="All Division">All Division</option>
                <option value="OTR">OTR</option>
                <option value="Dedicated">Dedicated</option>
              </Select>

              {/* Driver Leader Select */}
              <Select
                variant="flushed"
                py="2"
                px="4"
                color="gray.700"
                width="25%"
                focusBorderColor="crimson"
                value={selectedDriverLeader || ""}
                onChange={(e) => setSelectedDriverLeader(e.target.value)}
              >
                <option value="" disabled>
                  Select Driver Leader
                </option>
                {uniqueDriverLeaders.map((leader) => (
                  <option key={leader} value={leader}>
                    {leader}
                  </option>
                ))}
              </Select>

              {/* Requested Select */}
              <Select
                variant="flushed"
                py="2"
                px="4"
                color="gray.700"
                width="25%"
                focusBorderColor="crimson"
                value={selectedRequested || ""}
                onChange={(e) => setSelectedRequested(e.target.value)}
              >
                <option value="" disabled>
                  Select Requested
                </option>
                {uniqueRequested.map((request) => (
                  <option key={request} value={request}>
                    {request}
                  </option>
                ))}
              </Select>
            </Flex>
            <Flex alignItems="center" width="25%">
              {/* Switch for "All/Latest" with conditional rendering */}
              {selectedStatus === "Completed" && (
                <Flex alignItems="center" marginRight={showLatest ? 4 : 0}>
                  <FormControl
                    display="flex"
                    alignItems="center"
                    marginRight={showLatest ? 2 : 0}
                  >
                    <FormLabel htmlFor="latest-switch" mb="0">
                      {showLatest ? "Last" : "All"}
                    </FormLabel>
                    <Switch
                      id="latest-switch"
                      size="lg"
                      colorScheme="red"
                      defaultChecked={showLatest}
                      onChange={() => setShowLatest(!showLatest)}
                    />
                  </FormControl>

                  {/* Number Selector with conditional rendering */}
                  {showLatest && selectedStatus === "Completed" && (
                    <Box>
                      <NumberInput
                        size="md"
                        defaultValue={7}
                        focusBorderColor="crimson"
                        max={180}
                        min={0}
                        value={numberOfDays}
                        onChange={(valueString) =>
                          setNumberOfDays(Number(valueString))
                        }
                      >
                        <NumberInputField />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    </Box>
                  )}
                </Flex>
              )}
            </Flex>

            <Flex alignItems="flex-start">
              <Button
                colorScheme="gray"
                size="sm"
                variant="outline"
                textColor="gray.700"
                onClick={() => {
                  setSearchInput("");
                  setSelectedDivision("All Division");
                  setSelectedDriverLeader("All Driver Leader");
                  setSelectedRequested("All Requested");
                }}
              >
                Reset
              </Button>
              <Spacer width="2" />
              <IconButton
                colorScheme="gray"
                size="sm"
                variant="outline"
                aria-label="Refresh Database"
                icon={<RepeatIcon />}
                onClick={fetchData}
              />
            </Flex>
          </Flex>
          <DashboardCards
            totalRecords={data.length}
            currentItems={filteredData.length}
            averageTime={averageTime}
            selectedStatus={selectedStatus}
          />
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            style={{
              height: "calc(100vh - 300px)",
              width: "100%",
              overflowY: "auto",
              borderRadius: "10px",
            }}
          >
            {/* Table */}
            <Table variant="simple" size="sm" sx={BackgroundColor}>
              <Thead>
                <Tr>
                  <Th color="white" width="5%">
                    Status
                  </Th>
                  <Th
                    color="white"
                    width="10%"
                    onClick={() => {
                      if (sortColumn === "DriverName") {
                        setSortDirection(
                          sortDirection === "asc" ? "desc" : "asc"
                        );
                      } else {
                        setSortColumn("DriverName");
                        setSortDirection("asc");
                      }
                      setFilteredData(sortData("DriverName"));
                    }}
                    style={{ cursor: "pointer", position: "relative" }}
                  >
                    Driver
                    {sortColumn === "DriverName" && (
                      <span
                        style={{
                          position: "absolute",
                          fontSize: "10px",
                          color:
                            sortDirection === "asc" ? "turquoise" : "yellow",
                        }}
                      >
                        {sortDirection === "asc" ? "▲" : "▼"}
                      </span>
                    )}
                  </Th>
                  <Th
                    color="white"
                    width="10%"
                    onClick={() => {
                      if (sortColumn === "DriverCode") {
                        setSortDirection(
                          sortDirection === "asc" ? "desc" : "asc"
                        );
                      } else {
                        setSortColumn("DriverCode");
                        setSortDirection("asc");
                      }
                      setFilteredData(sortData("DriverCode"));
                    }}
                    style={{ cursor: "pointer", position: "relative" }}
                  >
                    Driver Code
                    {sortColumn === "DriverCode" && (
                      <span
                        style={{
                          position: "absolute",
                          fontSize: "10px",
                          color:
                            sortDirection === "asc" ? "turquoise" : "yellow",
                        }}
                      >
                        {sortDirection === "asc" ? "▲" : "▼"}
                      </span>
                    )}
                  </Th>
                  <Th
                    color="white"
                    width="10%"
                    onClick={() => {
                      if (sortColumn === "DriverLeaderName") {
                        setSortDirection(
                          sortDirection === "asc" ? "desc" : "asc"
                        );
                      } else {
                        setSortColumn("DriverLeaderName");
                        setSortDirection("asc");
                      }
                      setFilteredData(sortData("DriverLeaderName"));
                    }}
                    style={{ cursor: "pointer", position: "relative" }}
                  >
                    Driver Leader
                    {sortColumn === "DriverLeaderName" && (
                      <span
                        style={{
                          position: "absolute",
                          fontSize: "10px",
                          color:
                            sortDirection === "asc" ? "turquoise" : "yellow",
                        }}
                      >
                        {sortDirection === "asc" ? "▲" : "▼"}
                      </span>
                    )}
                  </Th>
                  <Th
                    color="white"
                    width="10%"
                    onClick={() => {
                      if (sortColumn === "Division") {
                        setSortDirection(
                          sortDirection === "asc" ? "desc" : "asc"
                        );
                      } else {
                        setSortColumn("Division");
                        setSortDirection("asc");
                      }
                      setFilteredData(sortData("Division"));
                    }}
                    style={{ cursor: "pointer", position: "relative" }}
                  >
                    Division
                    {sortColumn === "Division" && (
                      <span
                        style={{
                          position: "absolute",
                          fontSize: "10px",
                          color:
                            sortDirection === "asc" ? "turquoise" : "yellow",
                        }}
                      >
                        {sortDirection === "asc" ? "▲" : "▼"}
                      </span>
                    )}
                  </Th>
                  <Th
                    color="white"
                    width="10%"
                    onClick={() => {
                      if (sortColumn === "Created") {
                        setSortDirection(
                          sortDirection === "asc" ? "desc" : "asc"
                        );
                      } else {
                        setSortColumn("Created");
                        setSortDirection("asc");
                      }
                      setFilteredData(sortData("Created"));
                    }}
                    style={{ cursor: "pointer", position: "relative" }}
                  >
                    Created
                    {sortColumn === "Created" && (
                      <span
                        style={{
                          position: "absolute",
                          fontSize: "10px",
                          color:
                            sortDirection === "asc" ? "turquoise" : "yellow",
                        }}
                      >
                        {sortDirection === "asc" ? "▲" : "▼"}
                      </span>
                    )}
                  </Th>
                  <Th
                    color="white"
                    width="5%"
                    onClick={() => {
                      if (sortColumn === "CreatedBy") {
                        setSortDirection(
                          sortDirection === "asc" ? "desc" : "asc"
                        );
                      } else {
                        setSortColumn("CreatedBy");
                        setSortDirection("asc");
                      }
                      setFilteredData(sortData("CreatedBy"));
                    }}
                    style={{ cursor: "pointer", position: "relative" }}
                  >
                    Requested
                    {sortColumn === "CreatedBy" && (
                      <span
                        style={{
                          position: "absolute",
                          fontSize: "10px",
                          color:
                            sortDirection === "asc" ? "turquoise" : "yellow",
                        }}
                      >
                        {sortDirection === "asc" ? "▲" : "▼"}
                      </span>
                    )}
                  </Th>
                  {selectedStatus === "Incomplete" && (
                    <Th color="white" width="5%">
                      Days
                    </Th>
                  )}
                  {selectedStatus === "Incomplete" && (
                    <Th color="white" width="10%">
                      Last Attempted
                    </Th>
                  )}
                  <Th color="white" width="10%">
                    Safety Coaching Note (Requested)
                  </Th>
                  {selectedStatus !== "Incomplete" && (
                    <>
                      <Th
                        color="white"
                        width="5%"
                        onClick={() => {
                          if (sortColumn === "Completed") {
                            setSortDirection(
                              sortDirection === "asc" ? "desc" : "asc"
                            );
                          } else {
                            setSortColumn("Completed");
                            setSortDirection("asc");
                          }
                          setFilteredData(sortData("Completed"));
                        }}
                        style={{ cursor: "pointer", position: "relative" }}
                      >
                        Completed
                        {sortColumn === "Completed" && (
                          <span
                            style={{
                              position: "absolute",

                              fontSize: "10px",
                              color:
                                sortDirection === "asc"
                                  ? "turquoise"
                                  : "yellow",
                            }}
                          >
                            {sortDirection === "asc" ? "▲" : "▼"}
                          </span>
                        )}
                      </Th>
                      <Th color="white" width="5%">
                        Driver Leader Comment (Completed)
                      </Th>
                    </>
                  )}

                  <Th color="white" width="5%">
                    View
                  </Th>
                </Tr>
              </Thead>
              {/* Table Body */}
              <Tbody>
                {filteredData.map((item) => (
                  <Tr key={item.ID}>
                    <Td width="5%">{item.Status}</Td>
                    <Td width="10%">{item.DriverName || "-"}</Td>
                    <Td width="10%">{item.DriverCode || "-"}</Td>
                    <Td width="10%">{item.DriverLeaderName || "-"}</Td>
                    <Td width="10%">{item.Division || "-"}</Td>
                    <Td width="10%">
                      {item.Created
                        ? new Date(item.Created!).toLocaleDateString()
                        : "-"}
                    </Td>
                    <Td width="5%">{item.CreatedBy || "-"}</Td>
                    {selectedStatus === "Incomplete" && (
                      <Td width="5%">{item.Days || "-"}</Td>
                    )}
                    {selectedStatus === "Incomplete" && (
                      <Td width="10%">
                        {item.Attempted_Date_Time
                          ? new Date(
                              item.Attempted_Date_Time!
                            ).toLocaleDateString()
                          : "-"}
                      </Td>
                    )}
                    <Td width="15%">{item.SafetyCoachingNote || "-"}</Td>
                    {selectedStatus !== "Incomplete" && (
                      <>
                        <Td width="5%">
                          {item.Completed
                            ? new Date(item.Completed!).toLocaleDateString()
                            : "-"}
                        </Td>
                        <Td width="15%">{item.DriverLeaderComment || "-"}</Td>
                      </>
                    )}

                    <Td>
                      <IconButton
                        aria-label="View"
                        variant="ghost"
                        icon={<Eye28Regular color="white" />}
                        onClick={() => handleRowClick(item.ID, item.Status)}
                        _hover={{ bg: "gray.700" }}
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </motion.div>
        </VStack>
      )}
    </>
  );
}
